import * as React from 'react';
import {Card, CardContent,Divider} from "@mui/material";
import {
    BB1,
    DAS,
    SNK,
    Boy,
    Calendar,
    Man,
    Taxi,
    CGSKL007,
    CGDP001,
    CGDP002,
    CGDP003,
    CDPMADS001,
    CDPMADS003,
    CDPMADS004,
    CDPMADS005,
    CDPMADS006,
    CDPMADS007,
    CDPMADS008,
    CDPMADS009,
    CDPMADS010,
    CDPMADS011,
    CDPMADS012,
    CDPMADS014,
    CDPMADS015,
    CGDSST010,
    CGMCST008,
    CGCCBBST011, PMADS001, PMADS003, PMADS005, PMADS011, PMADS013, PMADS015, PMADS008, PMADS004
} from "../../assets/images/index";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {unwrapResult} from '@reduxjs/toolkit'
import {
    editItem,
    editItemDate,
    removeItem,
    cartSelector,
    addItem,
    lisItems, cartResumeSelector
} from '../../store/cart/cart';
import {useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import Button from "@mui/material/Button";
import GridViewIcon from "@mui/icons-material/GridView";
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";
interface ICardPackage{
    paragrahp_1:string
    paragrahp_2?:string
    paragrahp_3?:string
    date_reservation:string
    amount:number
    adult:number
    child:number
    type:'services'|'add-ons',
    id_tour:string
    transfer:boolean
}

const GetImage=({id_tour}: {id_tour: string})=> {
    const images_tour:image_type = {
        //    CZM
        "PMCZM001": <img src={CGDP001} alt="beach break "/>,
        "PMCZM003": <img src={CGDP002} alt="All You Can Eat Beach Break"/>,
        "PMCZM004": <img src={CGDP003} alt="Amazing Beach Break"/>,
        "PMCZM001-TF01": <img src={CGDP001} alt="Beach Break Transfer"/>,
        "PMCZM003-TF01": <img src={CGDP002} alt="All You Can Eat Beach Break + Transfer"/>,
        "PMCZM004-TF01": <img src={CGDP003} alt="Amazing Beach Break Transfer"/>,
        "PMCZM008": <img src={CGDSST010} alt="Dance & Salsa"/>,
        "PMCZM008-TF01": <img src={CGDSST010} alt="Dance & Salsa transfrt"/>,
        "PMCZM009": <img src={SNK} alt="Cozumel Driving tour Snorkel -Jade Cavern & Beach Break"/>,
        "PMCZM006": <img src={CGMCST008} alt="Mexican Cuisine Workshop & Tasting"/>,
        "PMCZM006-TF01": <img src={CGMCST008} alt="Mexican Cuisine Workshop & Tasting"/>,
        "PMCZM005": <img src={CGSKL007} alt="Cozumel Snorkel Tour: Coral Reefs, el Cielo & Beach Break"/>,
        "PMCZM007": <img src={CGCCBBST011} alt="Cuban Cigar Master & Beach Break"/>,
        //    PDC
        "PMPDC001": <img src={CGCCBBST011} alt="Snorkel Tour: Coral Reefs, el Cielo & Beach (show up PDC)"/>,
        "PMPDC002": <img src={CGCCBBST011} alt="Snorkel Tour: Coral Reefs, el Cielo & Beach (Hotel Pick up)"/>,
        "PMPDC004": <img src={CGCCBBST011} alt="Beach Break® Experience (Hotel Pick up)"/>,
        "PMPDC003": <img src={CGCCBBST011} alt="Beach Break ® Experience (show up PDC)"/>,
        //    ADDONS
        "PMADS001": <img src={PMADS001} alt="Fish SPA"/>,
        "PMADS003": <img src={PMADS003} alt="Snorkel Tour Xtabay"/>,
        "PMADS005": <img src={PMADS005} alt="Wave Runner 30 MIN"/>,
        "PMADS006": <img src={PMADS005} alt="Wave Runner 1 HR"/>,
        "PMADS011": <img src={PMADS011} alt="Manicure Basico"/>,
        "PMADS013": <img src={PMADS013} alt="Camas Lounge"/>,
        "PMADS015": <img src={PMADS015} alt="Hobie Cat 1 Hr"/>,
        "PMADS008": <img src={PMADS008} alt="Masaje Relajante 60 min"/>,
        "PMADS009": <img src={PMADS008} alt="Masaje Reflexologia 30 min"/>,
        "PMADS004": <img src={PMADS004} alt="Snorkel Gear "/>,
    };

    return images_tour[id_tour] ?? <img src={CGDP001} alt="breach break 1"/>;
}
interface image_type {
    [key: string]: any;
}
const getColor=({id_tour}: {id_tour: string})=> {
    const tour_background:image_type = {
        "CGDP001": 'bg-azul',
        "CGDP002": 'bg-azul',
        "CGDP003": 'bg-azul',
        "CGDPT004": 'bg-azul',
        "CGDPT005": 'bg-azul',
        "CGDPT006": 'bg-azul',
        "CGDSST010": 'bg-morado',
        "CDSJCBB012": 'bg-morado',
        "CGMCST008": 'bg-morado',
        "CGSKL007": 'bg-rosado',
        "CGCCBBST011": 'bg-rosado',
    };

    return tour_background[id_tour] ?? 'bg-azul';
}
export const CardPackagePayment=({id_tour,paragrahp_1,paragrahp_2,paragrahp_3,date_reservation,adult,child,transfer,type,amount}:ICardPackage)=> {
    const dispatch = useAppDispatch();
    const CartSelectorResume = useSelector(cartResumeSelector);
    const { t } = useTranslation();
    let {
        resume_currency,
    } = CartSelectorResume;

    return (
        <>

        <Card sx={{display: 'flex', marginBottom:'15px',background:'#fff',boxShadow:'0px 2px 6px -1px rgb(0 0 0 / 20%)',borderRadius:'10px'}} className="itemProcesoPago">
            <div className={`contImgPAY ${getColor({id_tour:id_tour})}`}>
                <GetImage id_tour={id_tour}></GetImage>
                <div className="cont-text-item"></div>
            </div>
            <Box sx={{display: 'contents', flexDirection: 'column'}} >
                <CardContent sx={{flex: '1 0 auto'}}>
                    <Grid md={6} className="Grid gridPrincipal gridProcesoPago">
                        <Grid className="Grid">
                            <div className="contGrid">
                                <div className="cont-grid">
                                     <Typography variant="h4" className="TodayShop fontSize25">{paragrahp_1} <span className="NunitoBold fontSize18">{paragrahp_2}</span> <span
                                        className="AshleyRegular fontSize18">{paragrahp_3}</span></Typography>
                                </div>
                                <div className="cont-grid">
                                    <img src={Calendar} alt="icon calendar" className="iconItem"/>
                                    <span>{t('date_of_reservation'??'',{ ns: namespaces.check_your_cart})}:{date_reservation}</span>
                                </div>
                                <div className="cont-grid">
                                    <div className="cont-txt-icon">
                                        <img src={Man} alt="icon man" className="iconItem"/>
                                        <span>  {

                                                id_tour === 'PMADS005' || id_tour === 'PMADS006'
                                                ? t('water_m' ?? '', { ns: namespaces.modal_addon })
                                                : id_tour === 'PMADS013'
                                                    ? t('beds' ?? '', { ns: namespaces.modal_addon })
                                                    : t('adults' ?? '', { ns: namespaces.modal_addon })

                                        } {adult}</span>
                                    </div>
                                    {
                                        type==='services' && <>
                                            <div className="cont-txt-icon">
                                                <img src={Boy} alt="icon boy" className="iconItem"/>
                                                <span>  {t('children'??'',{ ns: namespaces.check_your_cart})}: {child}</span>
                                            </div>

                                            <div className="cont-txt-icon">
                                                <img src={Taxi} alt="icon taxi" className="iconItem"/>
                                                <span> {t('transportation'??'',{ ns: namespaces.check_your_cart})}: </span><span>{transfer?'YES':'NO'}</span>
                                            </div>
                                        </>
                                    }


                                </div>
                            </div>
                        </Grid>
                        <Grid xs={6} className="cont-precio">
                            <Divider orientation="vertical" variant="middle" flexItem sx={{height:'60%',margin:'auto'}}/>
                            <div className="w-100">
                                <Typography className="AshleyRegular">TOTAL</Typography>
                                <Typography className="NunitoBold txt-30">${amount}</Typography>
                                <Typography className="NunitoBold txt-16">{resume_currency}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Box>
        </Card>
        </>
    );
}

